import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';

const navbarToggler = Collapse.getOrCreateInstance('#navbarNav', { toggle: false });

window.addEventListener('beforeunload', () => {
    navbarToggler.hide();
})

document.querySelectorAll('body >*:not(header)').forEach(element => {
    element.addEventListener('click', () => {
        navbarToggler.hide();
    })
})

const map = document.getElementById('map');

if (map) {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                mapListener();
                observer.disconnect();
            }
        })
    })
    observer.observe(map);
}


(g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({
    key: "AIzaSyB5e-iUzlDdd__9co9R92skwzm1F9SSnKg",
    v: "weekly",    
});



function mapListener() {
    let map;

    async function initMap() {
        const position = { lat: 48.7225548, lng: 21.2561387 };
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        map = new Map(document.getElementById("map"), {
            zoom: 15,
            center: position,
            mapId: "4d9970338220d0b7",
            clickableIcons: false
        });

        const img = document.createElement('img');
        img.src = "/img/people/card-03-300w.webp";
        img.width = "50";
        img.height = "50";
        img.style.borderRadius = "50%";

        const marker = new AdvancedMarkerElement({
            map: map,
            position: position,
            title: "Základná umelecká škola Márie Hemerkovej",
            content: img,
        });

        const infoWindow = new google.maps.InfoWindow({
            content: `<div>
            <h2 class="h6">Základná umelecká škola Márie Hemerkovej</h2>
            <a href="https://maps.app.goo.gl/LtZ3UAa6MNUjJmnq6" referrerpolicy="no-referrer" target="_blank">Zobraziť v Google Maps</a>
            </div>`
        });

        marker.addListener('click', () => {
            infoWindow.open({
                anchor: marker,
                map
            })
        });
    }

    initMap();
}

const langSk = document.getElementById('lang-sk');
const langEn = document.getElementById('lang-en');

async function getLangSettings() {
    try {
        const response = await fetch('/json/lang.json');
        const langMap = await response.json();
        // const langMap = JSON.parse(settings);

        langSk.addEventListener('click', event => {
            event.preventDefault();
            const pathname = location.pathname;
            // console.log('SK button' + ' ' + pathname);
            if (pathname.includes('/en/')) {
                // EN version
                for (const [sk, en] of Object.entries(langMap)) {
                    // console.log(pathname + ' ' + en + ' ' + sk);
                    if (pathname === en) {
                        location.pathname = sk;
                        return;
                    }
                }
                location.pathname = '/';
            }
        })

        langEn.addEventListener('click', event => {
            event.preventDefault();
            const pathname = location.pathname;
            // console.log('EN button' + ' ' + pathname);
            if (!pathname.includes('/en/')) {
                // SK version
                for (const [sk, en] of Object.entries(langMap)) {
                    // console.log(pathname + ' ' + sk + ' ' + en);
                    if (pathname === sk) {
                        location.pathname = en;
                        return;
                    }
                }
                location.pathname = '/en/';
            }
        })
    } catch (error) {
        console.log('No settings');
    }
}

getLangSettings();